import React from 'react';
import { Helmet } from 'react-helmet';

const TagsForAll = (props) => {
  return (
    <Helmet>
      <title>{props.pageTitle}</title>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-S2SVRK6LJB"></script>
      <script type="text/javascript">
       {`    window.dataLayer = window.dataLayer || [];
             function gtag(){
               dataLayer.push(arguments);
             }
             gtag('js', new Date());
             gtag('config', 'G-S2SVRK6LJB');`}
      </script>
      <meta name='X-Frame-Options' content='Denied' />      
      <meta name='X-Content-Type-Option' content='No sniff' />      
      <meta name='Referrer-Policy' content='No-referrer' />      
      <meta name='Strict-Transport-Security' content='max-age=31536000; includeSubDomains; preload' />
      <meta httpEquiv="Content-Security-Policy" content="default-src 'self' 'unsafe-inline'; img-src 'self' data: http://res.cloudinary.com https://www.googletagmanager.com/ https://i.ytimg.com; connect-src 'self' http://localhost:* ws://localhost:* https://www.google-analytics.com; style-src-elem 'self' 'unsafe-inline' https://fonts.googleapis.com; script-src-elem 'self' 'unsafe-eval' 'unsafe-inline' https://*.googletagmanager.com https://www.google.com/recaptcha/api.js https://www.gstatic.com/recaptcha/releases/zIriijn3uj5Vpknvt_LnfNbF/recaptcha__en.js; script-src 'unsafe-eval' 'unsafe-inline'; frame-src  https://www.google.com http://www.youtube.com" />
    </Helmet> 
  )
}

export default TagsForAll;
